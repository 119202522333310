import React from 'react';

//Home
const PageHome= React.lazy(() => import('./pages/HomePage/index'));

//Main Index
const PageIndex = React.lazy(() => import('./pages/IndexHome/Main'));

//Special
const PageError = React.lazy(() => import('./pages/Special/PageError'));

// Import all components
const Solutions = React.lazy(() => import('./pages/Solutions/Main'));
const Campaing = React.lazy(() => import('./pages/Campaing/Main'));
const Marketing = React.lazy(() => import('./pages/Marketing/index'));

//Utility
const PagePrivacy = React.lazy(() => import('./pages/Policies/PagePrivacy'));
const PageTerms = React.lazy(() => import('./pages/Policies/PageTerms'));
const PageContact = React.lazy(() => import('./pages/Special/PageContact'));
const PagePdf = React.lazy(() => import('./pages/PDF/index'));
const PageBlog = React.lazy(() => import('./pages/Blog/Main'));
const News = React.lazy(() => import('./pages/Important/News'));
const Webinar = React.lazy(() => import('./pages/Important/Webinar')); 
const PageContactPrivacy = React.lazy(() => import('./pages/Policies/ContactPrivacy'));
const PageCampaingPrivacy = React.lazy(() => import('./pages/Policies/CampaingPrivacy'));

//Marketing Modules
const CountModule = React.lazy(() => import('./pages/ModuleCount/Main'));
const EyeModule = React.lazy(() => import('./pages/ModuleCharacterization/Main'));

//Page Confirmation
const PageConfirmation = React.lazy(() => import('./pages/PageConfirmation'));

//temporal
const PagePrices = React.lazy(() => import('./pages/Prices/PagePrices2'));
const PageContact2 = React.lazy(() => import('./pages/Special/PageContact2'));
const nosotros = React.lazy(() => import('./pages/nosotrosTemporal'));


const routes = [
    
    // Landings
    { path: '/bioseguridad', component: Solutions },
    { path: '/reactivando-santander', component: Campaing },
    //{ path: '/precios', component: PagePrices },
    { path: '/destacado', component: News },
    { path: '/webinars', component: Webinar },

    //Modules Marketing
    { path: '/conteo-personas', component: CountModule },
    { path: '/caracterizacion-clientes', component: EyeModule },
    { path: '/marketing-retail', component: Marketing },
    { path: '/marketing', component: Marketing },

    //Utility
    { path: '/seguridad-informacion', component: PageTerms },
    { path: '/politicas-privacidad', component: PagePrivacy },
    { path: '/confirmacion', component: PageConfirmation},

    //privacy
    { path: "/contacto-privacidad", component: PageContactPrivacy},
    { path: "/reactivando-santander-privacidad", component: PageCampaingPrivacy},

    //Page Contact
    { path: '/contacto', component: PageContact },
    { path: '/contacto2', component: PageContact2 },

    //Page PDF
    { path: '/pdf', component: PagePdf },

    //Page Blog
    { path: '/blog', component: PageBlog },
    
    //Index
    { path: '/nosotros', component: PageIndex},
    { path: '/nosotros2', component: nosotros},

    //HomePage
    { path: '/', component: PageHome, exact : true }, 
    { component: PageError, isWithoutLayout : true, exact : false },
    
];

export default routes;
